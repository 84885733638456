import React from "react";
import logger from "./../../../../logger";

import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Constants from "./../../../../Utils/Constants";
import axios from "axios";
import { getHeaders } from "./../../../../Utils/Common";
import LoadingProgress from "./../../../../Common/LoadingProgress";
import toastMaker from "./../../../../Common/toastmaker";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import store from "./../../../../Common/storage";
import { error } from "jquery";

const CHARACTER_LIMIT = 160;

function SimpleDialog(props) {
  const { onTemplateSelected } = props;

  const { onClose, open } = props;
  const [serverData, setServerData] = React.useState(store.get("templates"));
  const [isLoading, setIsLoading] = React.useState(false);

  const refreshData = () => {
    setIsLoading(true);
    axios.get(Constants.getApiPathWithConfigId(Constants.templatesApiPath + '?per_page=100'), getHeaders())
              .then(response => {
                setServerData(response.data.data);
                setIsLoading(false);
              }).catch(error => console.error(error))
    
    // return axios
    //   .all([requestOne])
    //   .then(
    //     axios.spread((...responses) => {
    //       const responseTemplates = responses[0];
    //       setServerData(responseTemplates.data.data);
    //       store.set("templates", responseTemplates.data.data);
    //       setIsLoading(false);
    //     })
    //   )
    //   .catch((errors) => {
    //     console.error("error:", errors);
    //     toastMaker.makeToast("Some issue fetching templates..", {
    //       variant: "error",
    //     });
    //     setIsLoading(false);
    //   });
  };

  React.useEffect(() => {
    if (open === true) {
      refreshData();
    }
  }, [open]);

  const handleClose = () => {
    onClose();
  };

  const handleListItemClick = async (item) => {
    await onTemplateSelected(item);
    handleClose();
  };

  let dialogCOntent = (
    <div className="import-template-modal">
      <DialogTitle id="simple-dialog-title">Select a Template</DialogTitle>
      <DialogContent>
        <List
          aria-label="secondary mailbox folder"
          style={{ overflow: "auto" }}
        >
          {serverData?.filter(item => {
            if(item.is_mms == 1 && props.isMMS) {
              return true
            }
            if(item.is_mms == 0 && !props.isMMS) {
              return true
            }
            return false
          }).map((item, index) => {
            return (
              <ListItem
                key={"templateSelect_" + item.id}
                button
                onClick={(event) => handleListItemClick(item)}
              >
                <div className="template-info">
                  <div className="template-info__item">
                    <p>{item.template_name}</p>
                  </div>
                  <div className="template-info__item">
                    <div className="template-info__item--border">
                      {props.isMMS ? <p>{item.subject}</p> : <></> }
                      {props.isMMS ? <img src={item.file} width="200" /> : <></> }
                      <p>{item.template_text}</p>
                    </div>
                  </div>
                  {!props.isMMS && <div className="template-info__item">
                    <p>
                      {item.template_text?.length} / {CHARACTER_LIMIT}
                    </p>
                  </div>}
                </div>
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
      <DialogActions style={{ padding: "8px 24px 24px" }}>
        <span
          className="c-button text-uppercase"
          style={{ marginRight: "10px" }}
          onClick={handleClose}
        >
          Cancel
        </span>
      </DialogActions>
    </div>
  );

  if (isLoading) {
    return <LoadingProgress />;
  }

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        scroll={"body"}
      >
        {dialogCOntent}
      </Dialog>
    </>
  );
}

export default function SimpleDialogDemo({ onTemplateSelected, isMMS }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <span
        className="c-button c-button--secondary text-uppercase"
        onClick={handleClickOpen}
        style={{ marginRight: "20px" }}
      >
        Import Template
      </span>
      <SimpleDialog
        open={open}
        onClose={handleClose}
        onTemplateSelected={onTemplateSelected}
        isMMS={isMMS}
      />
    </>
  );
}
